import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AuthenticationService,
  Person,
  PersonRole,
  PersonRoleService
} from 'commons';

@Component({
  selector: 'app-forward',
  templateUrl: './forward.component.html',
  styleUrls: []
})
export class ForwardComponent implements OnInit {

  public loggedIn: boolean;
  public apps: AppItem[] = [];

  private localhost: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private personRoleService: PersonRoleService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.localhost = params.hs && String(params.hs).includes('localhost');
    });

    this.init();
  }

  private async init() {
    let person: Person;
    await this.authenticationService.getPerson().then(response => {
      person = response || undefined;
    });

    await this.authenticationService.isLoggedIn().subscribe(status => {
      this.loggedIn = status;
    });

    // no valid session
    if (!this.loggedIn) {
      this.router.navigate(['login']).then();
    }

    let role: PersonRole;
    await this.personRoleService.findById(person.role).then(response => {
      role = response;
    });

    await this.authenticationService.getToken().then(entity => {
      const token = entity.token;

      ForwardComponent.list(this.localhost).forEach(app => {
        app.link += token.replace('Bearer ', '');

        if (app.restricted) {
          // validate user access for application
          if (role && role.name === 'Geschäftsführer') {
            this.apps.push(app);
          }
        } else {
          this.apps.push(app);
        }
      });
    });
  }

  private static list(localhost): AppItem[] {
    return [
      {
        name: 'Team',
        linkText: 'team.sleeksys.com',
        link: localhost ? 'http://localhost:4200/#/?token=' : 'https://team.sleeksys.com/#/?token=',
        restricted: false
      },
      {
        name: 'Timesheet',
        linkText: 'time.sleeksys.de',
        link: localhost ? 'http://localhost:4200/#/?token=' : 'https://time.sleeksys.de/#/?token=',
        restricted: false
      },
      {
        name: 'Finance',
        linkText: 'finance.sleeksys.com',
        link: localhost ? 'http://localhost:4200/?token=' : 'https://finance.sleeksys.com/?token=',
        restricted: true
      }
    ];
  }
}

export interface AppItem {
  name: string,
  linkText: string,
  link: string,
  restricted: boolean
}
