import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccessToken, AuthenticationService} from 'commons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit {

  public isLogoutView = false;
  public form = {
    text: '',
    textClazz: 'alert alert-info',
    enabled: false
  };

  private host: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    if ((this.router.url === '/logout')) {
      this.authenticationService.logout();
      this.cd.markForCheck();

      setTimeout(() => {
        this.router.navigate(['login']).then(() => {
          this.isLogoutView = false;
        });
      }, 2000);
    } else {
      // login
      this.route.queryParams.subscribe(params => {
        this.host = params.hs && params.hs !== '' ? params.hs : null;
      });
    }
  }

  public onFormSubmit(elt: HTMLFormElement): void {
    this.isLogoutView = false;
    this.form.enabled = false;

    let email = null;
    let password = null;
    const inputs = elt.querySelectorAll('input');

    for (let i = 0; i < inputs.length; i++) {
      if (inputs.item(i).name == 'email') {
        email = inputs.item(i).value;
      }
      if (inputs.item(i).name == 'password') {
        password = inputs.item(i).value;
      }
    }

    // disable submit button
    elt.querySelector('button').disabled = true;

    this.authenticationService.login(email, password, (session: AccessToken) => {
      if (session && session.token) {
        const queryParams: any = {};
        if (this.host != null) {
          queryParams.hs = this.host;
        }

        this.router.navigate(['forward'], {queryParams: queryParams})
          .then(() => {
            this.authenticationService.getPerson()
              .then(person => {
                this.authenticationService.person = person;
              });
          });
      } else {
        // login failed
        this.form.text = 'Login failed.';
        this.form.textClazz = 'alert alert-warning';
        this.form.enabled = true;

        // re-enable submit button
        elt.querySelector('button')
          .removeAttribute('disabled');
      }
      this.cd.markForCheck();
    });
  }
}
